
import React from 'react'
import { Link } from 'react-router-dom' 


class PageNotFoundNew extends React.Component{
    // componentDidMount(){
    //     $('#root').addClass('error-page')
    // }
    // componentWillUnmount(){
    //      $('$root').removeClass('error-page')
    // }
    render(){
        return(
        <div className='error-page-new'>
            <section className="error">
            <div className="error-body">
                <div className="error-content">
                    <div className="circle">404</div>
                    <div className="text">
                        <p className="p-error-page">This account doesn’t exist</p>
                        <p className="p-error-page">Make sure you enter the URL correctly</p>
                    </div>
                </div>
            </div>
            </section>
        </div>
        
        )
    }
}
 
export default PageNotFoundNew

